<template>
  <div>
    <h2>圆片操作日志</h2>
  </div>
</template>

<script>
export default {
  name: "ProdRoundSkuLogs",
  activated() {
    document.title='圆片操作日志'
  }
}
</script>

<style scoped>

</style>
